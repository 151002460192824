.upholstery-background {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.upholstery-background--form {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.upholstery-logo-instance {
  width: 40%;
}

.upholstery-glassmorphism {
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(0.5rem);
}

.thankyou-msg {
  padding: 1rem;
  text-align: center;
  font-size: $font-size-base;
  color: white;
}
