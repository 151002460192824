@include media-breakpoint-down(sm) {
  .small {
    font-size: calc($font-size-xs * 0.5);
  }

  .social-media-container {
    text-align: center;
    margin: 1rem;
    float: none;
  }

  .logo-instance-lonely {
    width: 16%;
    height: auto;
    max-width: 100%;
  }
}
