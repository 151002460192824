@media (min-width: 200px) and (max-width: 330px) {
  .container-card--masiala {
    width: 112px !important;
    height: 128px;
  }
}

@media (min-width: 990px) and (max-width: 1026px) {
  .container-card--masiala {
    width: 88px !important;
    height: 128px;
  }
}
