@include media-breakpoint-down(lg) {
  .container-video-kizuna {
    height: 360px;
    .container-poster {
      .btn-play-kizuna {
        width: 58px;
        height: 58px;
      }
    }
  }

  .container-video-kizuna > div {
    width: 100% !important;
    height: 360px;
    overflow: hidden;
  }
}
