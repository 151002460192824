.sample-count-container-masiala {
  position: fixed;
  bottom: 32%;
  right: 0;
  z-index: 999;
  cursor: pointer;
}

.sample-count-text {
  padding: 1rem;
  margin: 0;
  background-color: #acc12f;
  border-radius: 0 12px 12px 0;
  color: $white;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
