@include media-breakpoint-up(up) {
  .header-main-menu-saxony {
    @include clearfix();
  }

  .main-menu-sections-saxony {
    display: none;
    margin: 0;
    @include clearfix();
    display: inline-block;

    &:last-child {
      float: right;
      position: relative;
    }
  }

  .saxony-main-menu-sections__section {
    display: inline-block;
  }

  .header-menu-button-saxony {
    height: $header-height-saxony;
    line-height: $header-height-saxony;
    margin: 0;
    padding: 0 0.5rem;
    font-size: 18px;
  }
}
