@include media-breakpoint-down(md) {
  .container-menu-masiala {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-main-menu-masiala {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .header-main-menu-masiala--open {
    display: block;
  }

  .main-menu-sections-masiala {
    display: block;
    max-height: 0;
    overflow: hidden;
    background-color: $reseda-green;
    transition: all 0.3s ease-out;
  }

  .main-menu-sections-masiala--open {
    max-height: 16rem;
  }

  .header-menu-button-masiala {
    height: $header-menu-button-masiala-height;
    line-height: $header-menu-button-masiala-height;
    margin: 0.5rem 1rem;
  }
}
