.container--color-insp-rv {
  padding: 3rem 5rem;
}

.container-color-samples {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.title--color-insp-rv {
  padding: 0;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: $gray-light;
}

.subtitle-sample--color-insp-rv {
  padding: 0;
  color: $gray-light;
  text-align: start;
  font-family: $font-family-base;
}

.slider__embed-container {
  height: 488px;
}

.slider__image-large-container {
  display: flex;
  justify-content: center;
  width: 60%;
  height: 464px;
}

.slider__container-samples-images {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  height: fit-content;
}

.slider__sample-image {
  display: flex;
  align-items: flex-end;
  width: 232px;
  height: 120px;
  margin: 0.5rem;
  padding: 0 1rem;
}

.label-slider__sample-image {
  color: $white;
}

.prev-slick-arrow {
  position: relative;
  right: 2rem !important;
}

.slick-next::before,
.slick-prev::before {
  content: none;
}

.grow-on-hover {
  box-shadow: 0 0 1px transparent;
  cursor: pointer;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  vertical-align: middle;
}

.grow-on-hover:hover {
  transform: scale(1.05);
}
