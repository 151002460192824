@include media-breakpoint-up(md) {
  .container-media {
    height: 36rem;

    .slick-list {
      .slick-track {
        height: 36rem;
      }
    }
  }

  .aligned-btn {
    text-align: start;
  }
}
