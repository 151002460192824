@include media-breakpoint-down(lg) {
  .container-card--quickship {
    // width: 160px;
    // height: 150px;
    width: 90%;
    margin-bottom: .75rem;
    max-height: fit-content;
  }

  .img-card--quickship {
    height: 150px;
  }

  .card-label--quickship {
    padding-top: 0.25rem;
    // font-size: $font-size-base * 0.75;
  }
}
