.header-saxony {
  display: flex;
  align-items: center;
  justify-content: space-between;

  
}

.header-static-saxony {
  position: absolute;
}

.logo-saxony {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height-saxony;
  cursor: pointer;

  img {
    width: 64%;
  }
}


