@include media-breakpoint-only(md) {
  .wrapper-banner--masiala {
    height: 580px !important;
  }

  .title-banner--masiala {
    font-size: 2.25rem !important;
  }

  .description-banner--masiala {
    font-size: 1.125rem !important;
  }

  .btn-masiala {
    font-size: 1.063rem;
  }

  .banner-slider--masiala > .slick-dots {
    border: none;
    position: absolute !important;
    bottom: 5px;
  }

  .banner-slider--masiala > .slick-dots:nth-child(2) {
    position: relative !important;
    bottom: -5px;
    border: none;
    left: 0;
  }

  .banner-slider--masiala > .slick-dots li.slick-active button::before {
    opacity: 0.75;
    color: white !important;
  }

  .banner-slider--masiala > .slick-dots li button::before {
    color: white;
  }
}