.wrapper-list--cleaning-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 1.125rem;
  line-height: normal;
}

.disclaimer {
  font-size: 0.875rem;
  font-style: italic;
  margin: 0;
  color: #6b6b6b !important;
  line-height: 1.3rem;
}

.doAndDonts--title {
  font-weight: 300 !important;
}

.color-donts {
  color: rgba(189, 1, 1, 0.521);
}

.fw-color-donts {
  font-weight: 600;
}

.clean-img {
  width: 120px;
}

// .wrapper-items--cleaning-sm {
//   width: 320px;
// }
