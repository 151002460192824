@include media-breakpoint-down(md) {
  .font-gray-color {
    font-size: 1rem;
  }

  .saxony--section-container {
    height: fit-content !important;
  }

  .saxony--section-adicional-info {
    height: 150px;
  }

  .saxony--characteristic-single-icon {
    width: 23px !important;
    height: 22px !important;
  }

  .saxony--container-card-icon {
    width: 42px;
    height: 42px;
  }

  .saxony--card::after {
    content: "";
    display: block;
    height: 1px;
    width: 25px;
    margin-left: 8px;
    border-bottom: 2px solid gray;
    padding-bottom: 14px;
  }

  .saxony--characteristic-main-icon {
    height: 75px;
  }

  .saxony--container-main-icon {
    height: fit-content;
  }
}
