@media (min-width: 2000px) {
  .title-banner--masiala {
    font-size: 4rem;
  }

  .description-banner--masiala {
    font-size: 2rem;
  }

  .btn-masiala {
    font-size: 1.75rem;
  }
}
