@include media-breakpoint-down(lg) {
  .container-info--sm-rv {
    padding: 0 1.5rem;
  }

  .large-img--sm-rv {
    padding: 0 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 360px;
  }
}
