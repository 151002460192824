@media (max-width: 330px) {
  .saxony--section-adicional-info {
    height: 220px !important;
  }

  .saxony--container-icons {
    flex-wrap: wrap;
    height: 140px;
  }
}

// @media (min-width: 1900px) {

//   .saxony--section-container,
//   .saxony--section-paragraph {
//     padding-inline: 130px;
//   }
// }
