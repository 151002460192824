@include media-breakpoint-up(lg) {
  .blue-box {
    width: 40%;
    position: absolute;
    top: 16%;
    right: 0%;
    z-index: 1;
  }

  .paragraph {
    font-size: $font-size-lg;
  }
}

// WARNING: use this for large screens
@media (min-width: 1441px) {
  .paragraph {
    width: 100%;
  }
}
