.hero {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100vw;
  height: 54vh;
  color: $white;
}

.hero-description-container {
  margin: 0 4rem;
  margin-bottom: 7rem;
  z-index: 1;

  .hero-kizuna-detail-sample-title {
    text-align: left;
    text-transform: capitalize;
    color: #6c757d;
    margin-bottom: 1rem;
  }

  .description-text--video {
    width: 100%;
    margin-bottom: 2rem;
  }

  .description-text {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 1.125rem;
  }
}

.img-banner-product--kizuna {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.videoembed {
  width: 100%;
  height: 100%;
  position: absolute;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
