@include media-breakpoint-down(md) {
  .container-media {
    height: 28rem;

    .slick-list {
      .slick-track {
        height: 28rem;
      }
    }
  }

  .aligned-btn {
    text-align: center;
  }
}
