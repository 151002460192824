%flex-stack {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 1rem;
  width: inherit;
}

%active {
  transform: translateY(-8px);
  box-shadow: 0 2px 12px #a3b2c2;
}

.color-palette-container {
  @extend %flex-stack;
  justify-content: center;
}

.collapse {
  @extend %flex-stack;
  height: 10.5rem;
}

.collapse-show {
  @extend %flex-stack;
}

.collapse-center {
  justify-content: center;
}

.card-color {
  margin: 0.2rem;
  cursor: pointer;
  transition: 0.4s ease all;

  &:hover {
    @extend %active;
  }
}

.card-color-active {
  transform: translateY(-4px);
  box-shadow: 0 2px 8px $very-dark-cyan;
}

.card-color--color {
  width: 5.5rem;
  height: 5.5rem;
  margin: 0.2rem;
}

.card-color--identifier {
  width: 100%;
  font-size: $sub-sup-font-size;
  text-align: left;
  text-transform: capitalize;

  p {
    margin: 0.1rem 0.5rem;
  }
}

.dropper {
  width: 2rem;
}

.bounce {
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0rem);
  }
  40% {
    transform: translateY(1rem);
  }
  60% {
    transform: translateY(0.5rem);
  }
}
