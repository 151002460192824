.container-loader-result-samplesList {
  color: #98989A;
  font-family: 'Gill Sans';
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-spinner {
    width: 3rem;
    height: 3rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 0;
  }
}