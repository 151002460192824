@include media-breakpoint-down(md) {
  .design-navigator--container {
    display: block;

    .filtered-references--container {
      display: block;
    }

    .card-pattern {
      height: 264px;
    }
  }
}
