.footer--quick-ship {
  height: 76px;
  background: #D9D9D9;
  
  width: 100%;

  img{
    transition: all 0.3s ease;
    &:hover {
      filter: brightness(1.2);
    }
  }
  
}