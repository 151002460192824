.header-background--rv {
  background-color: black !important;
}

.footer-background--rv {
  background-color: black !important;

  span {
    color: white;
  }

  .footer-sections {
    width: fit-content;
    margin-left: auto;
  }
}
