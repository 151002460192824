@include media-breakpoint-up(xl) {
  .saxony--characteristic-single-icon {
    width: 45px !important;
    height: 35px !important;
  }

  .saxony--container-card-icon {
    width: 64px;
    height: 64px;
  } 

  .saxony--card::after {
    content: "";
    display: block;
    height: 1px;
    width: 50px;
    margin-left: 8px;
    border-bottom: 2px solid gray;
    padding-bottom: 14px;
  }
}
