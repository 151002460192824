@include media-breakpoint-down(lg) {
  .container-imgs--segm-hotelery {
    justify-content: center;
    margin: 1.5rem 0;
  }

  .main-img {
    position: relative;
    width: 100%;
  }

  .secondary-img {
    position: absolute;
    display: block;
    top: 64%;
    right: inherit;
    width: 400px;
  }

  .info--segm-hotelery--technical-upholstery {
    width: 100%;
    margin-top: 7rem;
  }
}
