.container-video {
  position: relative;
  width: 100%;
}

.poster {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.container-poster {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  aspect-ratio: 16/9;
}

.btn-play {
  position: absolute;
  color: $gray-300;
  border-color: $gray-300;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 96px;
}
