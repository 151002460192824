@media (min-width: 200px) and (max-width: 360px) {
  .container-card--quickship {
    width: 95% !important;
    // width: 112px !important;
    min-height: 128px;
    max-height: 140px;
    // padding: 0 0.2rem;
  }
}

@media (min-width: 990px) and (max-width: 1026px) {
  .container-card--quickship {
    width: 88px !important;
    height: 128px;
  }
}
