@include media-breakpoint-up(lg) {
  .container-menu-masiala {
    padding-left: 90px;
    padding-right: 90px;
  }

  .logo-masiala {
    display: flex;
    justify-content: start !important;
    align-items: center;
    height: $header-height;
    cursor: pointer;
  
    img {
      width: 64%;
    }
  }

  .container-menu-masiala {
    padding-left: 80px;
    padding-right: 80px;
  }

  .header-main-menu-masiala {
    @include clearfix();
  }

  .main-menu-sections-masiala {
    display: none;
    margin: 0;
    @include clearfix();
    display: inline-block;

    &:last-child {
      float: right;
      position: relative;
    }
  }

  .main-menu-sections__section-masiala {
    display: inline-block;
  }

  .header-menu-button-masiala {
    height: $header-height;
    line-height: $header-height;
    margin: 0;
    padding: 0 0.5rem;
    font-size: 18px;
  }
}
