@include media-breakpoint-down(lg) {
  .container--desc-sx {
    margin: 1rem 0;
  }

  .info--desc-sx {
    margin: 1.5rem 2.5rem;
  }

  .separator-rv {
    display: none;
  }
}
