@include media-breakpoint-up(lg) {
  .footer-logo--masiala {
    height: 40px;
    padding-right: 107.96px;
  }

  .section-video__description-valencia {
    margin: 80px 0;
  }
}
