@include media-breakpoint-up(lg) {
  .container-banner--masiala--title-and-decription {
    padding-left: 90px;
  }
  
  .title-banner--masiala {
    margin-bottom: 0.875rem;
  }

  .container--btn-masiala {
    margin-bottom: 218px;
  }

  .btn-masiala {
    font-size: 1.125rem;
  }

  .image-hiloft-rpet-banner {
    width: 50%;
  }

  .image-bio-attributed-banner {
    width: 20%;
  }

  .banner-slider--masiala > .slick-next {
    position: absolute;
    right: 4%;
  }
  
  .banner-slider--masiala > .slick-prev {
    position: absolute;
    left: 3%;
    z-index: 2;
    transform: rotate(-180deg);
  }
  
  .banner-slider--masiala > .slick-dots {
    position: absolute;
    border: none;
    bottom: 20px;
  }

  .banner-slider--masiala > .slick-dots li button::before {
    font-size: 0.625rem;
  }

  .banner-slider--masiala > .slick-dots li.slick-active button::before {
    opacity: 0.75;
    color: white !important;
  }

  .banner-slider--masiala > .slick-dots li button::before {
	  color: white !important;
    font-size: 0.625rem;
  }
}