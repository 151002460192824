.samplesList-wtihout-result-text{
  color: #98989A;
  font-family: 'Gill Sans';
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 3rem;
    font-size: 1.5rem;
  }
}