@include media-breakpoint-down(lg) {
  .container-card--masiala {
    width: 160px;
    height: 130px;
    margin-bottom: .75rem;
  }

  .img-card--masiala {
    height: 80px;
  }

  .card-label--masiala {
    padding-top: 0.25rem;
    font-size: $font-size-base * 0.75;
  }
}
