@include media-breakpoint-down(lg) {
  .container-card--rv {
    width: 100%;
    height: 104px;
  }

  .img-card--rv {
    height: 80px;
  }

  .card-label--rv {
    padding-top: 0.25rem;
    font-size: $font-size-base * 0.75;
  }
}
