@include media-breakpoint-down(md) {
  .how-works-container {
    display: block;
    text-align: center;
  }

  .blue-box {
    margin: 1rem 0;
    text-align: left;
  }
}
