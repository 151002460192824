@include media-breakpoint-down(lg) {
  .wrapper-samples-details--masiala {
    // margin: 1rem $spacer * 1;
    // padding: 0 $spacer * 0.5;
  }

  .large-img--quickship {
    border-radius: 1rem;
    max-height: 100%;
    max-width: 100%;
  }

  .btn-request-catalogue-masiala {
    margin-block: 7px;
    font-size: .85rem;
    max-width: fit-content;
    max-height: 38px;
    border-radius: 5px !important;
    padding: 9px;
  }

  .btn-request-add-sample-masiala {
    margin-block-start: 20px;
    font-size: .9rem;
    max-width:  fit-content;
    max-height: fit-content;
    height: 40px;
    border-radius: 5px !important;
    padding: 9px;
  }

  .sample-detail-selected--quickship {
    font-size: 0.875rem;
  }
}
