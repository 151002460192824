@include media-breakpoint-down(lg) {
  #section-1-gif {
    span {
      font-size: 1rem;
    }

    strong {
      font-size: 1rem;
    }
  }

  .animated-chart--masiala {
    height: 296px;
  }

  .subtitle-sustainable-modal--masiala {
    font-size: 1.5rem;
    text-align: center;
  }

  .subtitle-section-3-sustainable-modal--masiala {
    font-size: 1.5rem;
    text-align: center;
  }

  .img-phthalate--masiala {
    width: 112px;
    height: 24px;
  }

  .img-pfas--masiala {
    width: 48px;
    height: 24px;
  }

  .img-heavy-metals--masiala {
    width: 88px;
    height: 24px;
  }

  .img-biocides--masiala {
    width: 96px;
    height: 24px;
  }

  .img-antimicrobial--masiala {
    width: 120px;
    height: 24px;
  }

  .img-flame-retardant--masiala {
    width: 144px;
    height: 24px;
  }

  .sustainable-modal--produced--with-container,
  .sustainable-modal--recicled-bottles {
    text-align: center;
    padding-inline: 1rem;
  }
}
