@include media-breakpoint-down(lg) {
  .sustainable-title--masiala {
    margin: auto;
    font-size: 1rem;
  }

  .h-80 {
    height: inherit;
    margin-top: 0.5rem;
  }
}
