@include media-breakpoint-up(lg) {
  .wrapper-samples-details--masiala {
    padding-left: 90px;
    padding-right: 90px;
  }

  .title-samples-details--masiala {
    margin: 2rem 0rem;
  }

  .large-img--masiala {
    border-radius: 1rem;
    max-height: 358px !important;
  }

  .large-img--masiala > img {
    height: 100%;
  }

  .btn-request-add-sample-masiala {
    max-width: 355px;
  }
}
