.wrapper-samples-details--masiala {
  padding: 0 $spacer * 1.5;
}

.title-samples-details--masiala {
  color: $slate-gray;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1.5rem 0rem;
}

.container-search-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.container-master-sample {
  position: relative;
}

.large-img--masiala {
  border-radius: 1rem;
}

.large-img--masiala > img {
  height: 100%;
}

.sample-detail-selected--masiala {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 0.25rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: rgba(108, 117, 125, 0.6);
  color: $white;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.btn-request-width-de {
  max-width: 424px;
}

.sx-btn {
  border-radius: 30px;
}
