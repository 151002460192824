.home-slide {
  position: relative;
  background-color: $skeleton-background;
  background-position: center center;
  background-size: cover;
  padding-top: $header-height;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.prannatex-title, .prannatex-description {
  color: $slate-gray;
}

.prannatex-button {
  background-color: $slate-gray !important;
  color: white !important;
}

.home-slide__content {
  position: relative;
  z-index: 2;
}
