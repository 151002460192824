@include media-breakpoint-down(sm) {
  .icon {
    width: 5rem;
    height: 5rem;
  }

  .icon-no-text {
    width: 7rem;
    height: 7rem;
  }
}
