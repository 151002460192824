@include media-breakpoint-down(sm) {
  .collapse {
    margin: 0;
  }

  .collapse-show {
    margin: 0;
  }

  .card-color {
    margin: 0.2rem;
  }

  .card-color--color {
    width: 3.5rem;
    height: 3.5rem;
  }

  .card-color--identifier {
    font-size: 0.5rem;
  }

  .color-palette-container {
    @extend %flex-stack;
    justify-content: center;
    margin: 0;
  }
}
