.container-texture {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 4rem;
}

.img-section {
  width: 75%;
  aspect-ratio: 16 / 9;
}

.img--texture {
  position: absolute;
  left: 5%;
  top: 10%;
  height: 100%;
}
