@include media-breakpoint-up(md) {
  .icon {
    width: 3.75rem;
    height: 3.75rem;
  }

  .icon-no-text {
    width: 5.75rem;
    height: 5.75rem;
  }
}
