.sample-count-container-quickship {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 9999;
  // z-index: -1;
  // border: 2px solid red;

  display: flex;
  align-items: center;
}

.sample-count-text-quickship {
  padding: 1rem;
  margin: 0;
  background-color: #00575F;
  border-radius: 0 12px 12px 0;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    box-shadow: 0px 0px 10px #00575fd5;
  }
}
