@media (min-width: 200px) and (max-width: 330px) {
  .sustainable-compilance-container-img > img {
    width: 57px !important;
  }

  .sustainable-compilance-container-img {
    width: 70px !important;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .img-sustainable-backing-profile-rpet {
    width: 112px !important;
  }

  .img-sustainable-backing-profile-global {
    width: 104px !important;
  }

  .img-sustainable-manufacturing--carbon-neutral {
    position: relative;
    top: 2px;
    width: 88px !important;
  }

  .img-sustainable-manufacturing--iso14001, .img-sustainable-manufacturing--iso45001, .img-sustainable-manufacturing--iso50001 {
    width: 64px !important;
  }

  .img-sustainable-compilances--reach {
    position: relative;
    top: 5px;
    width: 105% !important;
  }

  .img-sustainable-compilances--rohs {
    width: 68px !important;
  }
}