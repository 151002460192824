.footer {
  height: $footer-height;
  background-color: $white;
}

.footer-logo {
  span.small {
    color: grey;
    line-height: 12px;
  }
}

.footer-logo-img {
  max-width: 100%;
  max-height: 25px;
  height: auto;
}

p {
  font-size: 17px;
}

ul {
  list-style: none;
  padding: 0 !important;

  li {
    font-size: 12px;
    line-height: 20px;

    a {
      color: $gray;
      text-decoration: none;

      &:hover {
        color: $gray;
        text-decoration: underline;
      }
    }
  }
}

.followus {
  h6 {
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin: 0;
  }
}
