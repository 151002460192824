@include media-breakpoint-down(lg) {
  .downArrowDesktop-quickshippage {
    display: none;
  }

  .home--products-container-product {
    width: 100% !important;
    max-height: 100vh;
    align-items: center;
    justify-content: flex-end;

    section {
      padding-bottom: 12rem;
    }

    .home--products-container-arrow-down {
      position: absolute;
      bottom: 6rem;
      display: flex;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &:nth-last-child(1) .home--products-container-arrow-down img {
      display: none;
    }
  }
}
