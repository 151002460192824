.detailed-store-box {
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 1.3rem;
  position: absolute;
  left: 0;
  top: 0;
  overflow: scroll;

  p {
    color: black !important;
  }

  a {
    color: black;
  }
}
