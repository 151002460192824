.header-burger {
  display: flex;
  height: 100%;
  align-items: center;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

.burger .line {
  width: 2.5rem;
  height: 0.125rem;
  display: block;
  margin: 0.5rem auto;
  transition: all 0.3s ease-in-out;
}

.burger:hover {
  cursor: pointer;
}

.is-active .line:nth-child(2) {
  opacity: 0;
}

.is-active .line:nth-child(1) {
  transform: translateY(0.625rem) rotate(45deg);
}

.is-active .line:nth-child(3) {
  transform: translateY(-0.625rem) rotate(-45deg);
}
