%flex-stack {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 1rem;
  width: inherit;
  transition: all 250ms linear;
}

.color-palette-container {
  @extend %flex-stack;
  justify-content: center;
}

.table-accordion--collapse {
  @extend %flex-stack;
  height: 0;
}

.table-accordion--collapse-show {
  @extend %flex-stack;
  height: 16rem;
  overflow: hidden;
}

.full-container {
  height: 100% !important;
}

.product-specs-accordion {
  margin: 1rem 0;
}

.product-specs-accordion__title {
  border: $table-border-width solid $table-border-color;
  margin: 0;
  color: $gray-light;
  padding: 1rem $table-cell-padding-y;
  font-size: $h5-font-size;

  i {
    float: right;
  }
}

.product-specs-accordion__collapse {
  border: $table-border-width solid $table-border-color;
  border-top: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.product-specs-table {
  margin: 0;
  table-layout: fixed;
}
