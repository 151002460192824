@include media-breakpoint-down(lg) {
  .sample-count-container-masiala {
    display: block;
    position: fixed !important;
    transform: none;
    right: 0;
    width: 100%;
    bottom: 0;
    padding: 0;
    border-radius: 0px !important;
  }

  .sample-count-text {
    border-radius: 0 !important;
  }
}
