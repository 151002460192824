@include media-breakpoint-down(sm) {
  .slider__image-large-container {
    width: 50%;
    height: 280px;
  }

  .slider__container-samples-images {
    width: 50%;
  }

  .slider__sample-image {
    width: 64px;
    height: 58px;
  }

  .label-slider__sample-image {
    font-size: x-small;
  }
}
