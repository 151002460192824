@include media-breakpoint-down(md) {
  .container-in-some-steps {
  height: 100vh;
}
  
  .hero-container {
    height: 24vh;
  }

  .hero-container--description {
    height: 36vh;
  }

  .hero-title--margin-mobile {
    margin-top: 3.5rem !important;
  }

  .hero-description {
    margin: 0;
    font-size: $font-size-sm;
  }

  .form-container {
    display: block;
    height: 64vh;
  }
}
