@include media-breakpoint-down(sm) {
  .home-slide__form__content {
    height: 100%;
    position: relative;

    > .container {
      height: 100%;

      > .row {
        height: 100%;

        > [class*="col"] {
          height: 100%;
        }
      }
    }
  }

  .slide-content__fomr {
    position: absolute;
  }

  .slide-content__fomr--left {
    bottom: 0;
    padding: 0 1rem 4rem 1rem;
  }

  .slide-content__form__title {
    margin-top: 3rem;
  }
}
