@include media-breakpoint-down(sm) {
  .table-accordion--collapse-show {
    @extend %flex-stack;
    height: 20rem;
  }

  .product-specs-accordion__title {
    border: $table-border-width solid $table-border-color;
    margin: 0;
    color: $gray-light;
    padding: 1rem $table-cell-padding-y-sm;
    font-size: $h5-font-size;

    i {
      float: right;
    }
  }
}
