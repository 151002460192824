@include media-breakpoint-up(xl) {
  .slider__image-large-container {
    align-items: center;
  }

  .slider__sample-image--even {
    margin-right: 0;
    padding-right: 0;
  }
}
