.container-sustainable-product-profile--masiala {
  .item-accordion {
    color: $viridian-green;
    border-bottom: 2px solid $viridian-green;
    p {
      color: $viridian-green !important;
    }
  }
  .item-accordion-mobile {
    color: $viridian-green;
    border-bottom: 2px solid $viridian-green;
    p {
      color: $viridian-green !important;
    }
  }
}

.sustainable-title--masiala {
  margin-block-start: 1.8rem;
  margin-block-end: .2rem;
  color: $dim-gray;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.h-80 {
  height: 5rem;
}

.sustainable-btn--masiala {
  color: $white;
  background-color: $slate-gray;
  border-radius: 20px;
  border: 0.0625rem solid $white;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 55px;
  max-width: 530px;
  width: 100%;

  &:hover {
    color: $white;
  }
}

.sustainable-divider--masiala {
  width: 280px;
}

.sustainable-over-relevant-wrd {
  color: $reseda-green;
}

