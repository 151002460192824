%active {
  transform: translateY(-0px);
  border: 2px solid #98989A;
}

.card-color-quickship {
  margin: 0.2rem;
  cursor: pointer;
  transition: 0.3s ease all;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid #FFFFFF;

  &:hover {
    @extend %active;
  }
}

.card-color-active {
  transform: translateY(-4px);
  box-shadow: 0 2px 8px #606060;
}

.card-color--color {
  width: 5.5rem;
  height: 5.5rem;
  margin: 0.2rem;
}

.card-color--identifier {
  width: 100%;
  font-size: 10px;
  text-align: left;
  text-transform: capitalize;

  p {
    margin: 0.1rem 0.5rem;
  }
}

.container-card--quickship {
  position: relative;
  width: 100%;
  max-width: 105px;
  // height: 141px;
  // height: fit-content;
  max-height: 160px;
  padding-bottom: 0.3rem;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  font-family: 'Gramatika';

  @media (max-width: 900px) {
    max-width: 100%;
  }

  @media (max-width: 525px) {
    .img-card--quickship {
      height: 80px;
    }
  }
}

.actived-card--masiala {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 24px 32px;
    border-color: transparent transparent #5f7052 transparent;
  }
}

.img-card--quickship {
  width: 100%;
  // height: 72px;
  aspect-ratio: 1 / 1;
}

.card-container-info--quickship {
  padding: 0.25rem;

  p {
    margin: 0;
  }
}

.card-label--quickship {
  // text-transform: uppercase;
  color: #606060;
  // font-family: "Gill Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.card-stock--quickship {
  // color: gray;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.14px;

  color: #606060;
  // font-family: "Gill Sans";
}

.name-collection {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  border-radius: 0px 12px 0px 0px;
  background: rgba(152, 152, 154, 0.50);
  min-height: 35px;

  color: #FFF;
  // font-family: "Gill Sans";
  // font-family: 'Gramatika';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: end;
  padding: 0 0.5rem 0.2rem 0.2rem;
  width: 90%;
  line-height: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
}

// Row 1 
.btn-go-back-quickship {
  display: flex;
  color: #000;
  align-items: center;
  justify-content: flex-start;
  // gap: 0.5rem;
  text-decoration: none !important;
  min-width: 100px;

  &:visited {
    color: #000;
    // text-decoration: none !important;
  }

  &:hover{
    color: #000;
    text-decoration: underline !important;
  }

  @media (max-width: 1024px) {
    gap: 1rem;
  }
}