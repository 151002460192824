.bg-reseda-green {
  background-color: $reseda-green;
  padding-block: 60px !important;
}

#section-1-gif {
  span {
    color: #606060;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  strong {
    color: $reseda-green;
    font-family: $font-family-title;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.animated-chart--masiala {
  height: 496px;
}

.subtitle-sustainable-modal--masiala {
  color: $white;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}

.subtitle-section-3-sustainable-modal--masiala {
  color: #5f7052;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.img-phthalate--masiala {
  width: 130px !important;
  height: 48px;
}

.img-pfas--masiala {
  width: 72px;
  height: 48px;
}

.img-heavy-metals--masiala {
  width: 108px !important;
  height: 48px;
}

.img-biocides--masiala {
  width: 110px !important;
  height: 48px;
}

.img-antimicrobial--masiala {
  width: 120px;
  height: 48px;
}

.img-flame-retardant--masiala {
  width: 180px !important;
  height: 48px;
}
