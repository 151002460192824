@include media-breakpoint-down(md) {
  .thank-you-message--description {
    font-size: 1rem;
    line-height: normal;
  }

  .thanks-you-message--title {
    font-size: 1.5rem;
  }

  .thank-you-message--btn {
    font-size: 1.125rem;
  }
}