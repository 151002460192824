@include media-breakpoint-up(md) {
  .description-breeze {
    font-size: 18px;
  }

  .scene--img {
    width: 15.625rem;
    height: 22.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .fabrics {
    background-size: 190% 235% !important;
    background-position-x: -190px;
  }

  .chair {
    background-size: 100% 170% !important;
    background-position-y: -130px;
  }

  .samples {
    background-size: 490% 250% !important;
    background-position-y: -230px;
  }

}