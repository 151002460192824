.locations-list {
  //background: #E2E2E2;
  top: 10px;
  left: 10px;
  z-index: 99;
  position: absolute;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  line-height: 25px;
}

.locations-list--stores {
  background: white;
  top: 120px;
  left: 10px;
  z-index: 99;
  position: absolute;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  line-height: 25px;
}

.locations-list-stores {
  position: relative;
}

.locations-list-stores__cards {
  height: 300px;
  overflow: scroll;
  background-color: white;
  color: black;

  p {
    color: black;
  }

  .no-results {
    height: 100%;

    p {
      color: black;
    }
  }
}

.store-card {
  padding: 1.3rem;
  border-bottom: 1px solid #d1d1d1;

  &:last-child {
    border-bottom: none;
  }

  a {
    color: black;
  }
}

.store-card--highlighted {
  background-color: $dark-cyan;
  color: white;

  p,
  a {
    color: white !important;
  }
}
