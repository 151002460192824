@include media-breakpoint-down(md) {
  .accordion--kizuna {
    .button-accordion--kizuna {
      font-size: 1rem;
    }

    .item-accordion {
      padding: 0 1rem;
    }
  }
}
