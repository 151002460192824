@include media-breakpoint-up(lg) {
  .wrapper-samples-details--masiala {
    padding-left: 90px;
    padding-right: 90px;
  }

  .title-samples-details--masiala {
    margin: 2rem 0rem;
  }
}
