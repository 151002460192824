input.form-control,
select.form-control,
textarea.form-control {
  &:focus {
    border-color: gray;
    box-shadow: none !important;
  }

  font-family: "Gill Sans", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  letter-spacing: 1px;
  border: 2px solid #bdbdbd;

  &::placeholder {
    color: gray !important;
    font-family: "Gill Sans", sans-serif;
    font-weight: lighter;
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

.select-color {
  letter-spacing: 1px;
}

.footer-form-container {
  height: 50px !important;
}

.sampling-request-modal {
  h1 {
    font-family: "Dala Moa", sans-serif;
    color: gray !important;
  }

  h4 {
    font-family: "Dala Moa", sans-serif;
    color: gray !important;
  }

  p {
    color: gray;
  }
}

.button-form-masiala {
  border-radius: 0px;
  margin-block: 12px;
  background-color: #606060;
  border-color: #606060;
  font-family: $font-family-base;
}

.button-form-masiala:hover {
  background-color: #606060;
  border-color: #606060;
}

.button-form-masiala:disabled {
  background-color: #6060608c;
  border: none;
  outline: none;
}

.button-form-masiala:active {
  background-color: #606060;
  box-shadow: none !important;
  outline: none;
}