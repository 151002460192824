@use "sass:math";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Type
$font-family-base: "Gill Sans", sans-serif;
$font-family-title: "Dala Moa", sans-serif;

// Colors
$dark-cyan: #04a0a4;
$very-dark-cyan: #046e70;
$cyan-darker: #00575f;
$slate-gray: #6c757d;
$gray: #4a4a4a;
$gray-light: #777777;
$gray-lighter: #f9f8f5;
$dim-gray: #606060;
$timberwolf: #cfcfcf;
$silver: #c2c2c2;
$platinum: #d9d9d9;
$anti-flash-white: #ebebeb;
$skeleton-background: #f1f1f1;
$green: #0c7c62;
$green-light: #8dc63f;
$reseda-green: #5f7052;
$viridian-green: #00806f;
$sizzling-red: #f05d5e;
$gunmetal: #273343;

$primary: $very-dark-cyan;
$secondary: $dark-cyan;
$dark: $black;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "gray": $gray-light
);

// creating custom classes
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// font size
$font-size-xs: ($font-size-base * 0.75);

// Grid
$grid-extra-small-gutter-width: 8px;
$grid-small-gutter-width: 16px;
$grid-medium-gutter-width: 24px;
$grid-gutter-width-base: $grid-gutter-width !default;
$grid-large-gutter-width: 35px;
$grid-extra-large-gutter-width: 40px;
$grid-gutter-width-half: math.div($grid-gutter-width, 2);

$grid-gutter-widths: (
  extra-small: $grid-extra-small-gutter-width,
  small: $grid-small-gutter-width,
  medium: $grid-medium-gutter-width,
  regular: $grid-gutter-width-base,
  large: $grid-large-gutter-width,
  extra-large: $grid-extra-large-gutter-width
);

// Sizing
$header-height: 3.75rem;
$footer-height: 3.75rem;
$hero-bg-width-md: 50vw;
$hero-bg-width-lg: 100%;
$hero-bg-width-xl: 100%;

:export {
  headerHeight: $header-height;
}
