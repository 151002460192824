@include media-breakpoint-only(md) {
  .container-card--quickship {
    width: 90% !important;
    height: 200px !important;
    margin-bottom: .75rem;
    max-height: 300px;
    max-width: 100%;
  }

  .img-card--quickship {
    height: 150px !important;
  }
}
