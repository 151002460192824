@media (min-width: 2000px) {
  .container--desc-sx {
    margin: 1rem 0;
    height: 750px !important;
    margin-bottom: 4rem;
  }

  .saxony--chair-img {
    width: 100%;
    height: 100%;
  }

  .saxony--container-chair-img {
    height: 100%;
  }
}
