@include media-breakpoint-up(xxl) {
  .container-video-masiala {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .container-video-masiala > div {
    width: 100% !important;
    height: 600px !important;
    overflow: hidden;
  }

  .container-video-masiala > div > div {
    width: 100% !important;
    height: 100% !important;
  }

  .poster-masiala {
    height: 140%;
  }
}
