@include media-breakpoint-up(md) {
  .main-header-cleaning-guide-marine {

    .header-main-menu {
      @include clearfix();
    }
  
    .main-menu-sections {
      display: none;
      margin: 0;
      @include clearfix();
      display: inline-block;
  
      &:last-child {
        float: right;
        position: relative;
      }
    }
  
    .main-menu-sections__section {
      display: inline-block;
    }
  
    .header-menu-button {
      height: $header-height;
      line-height: $header-height;
      margin: 0;
      padding: 0 0.5rem;
    }
  
    .header-menu-button--active:after {
      background: $very-dark-cyan;
      content: "";
      display: block;
      height: $menu-underline-height;
      transform: scaleX(1);
    }
  
    .cleaning-guide--header_select_lang-header .css-10qsew5-menu {
      margin-top: 18px;
      width: 180px;
      left: -110px;
    }

  }
}
