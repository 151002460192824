.header--kizuna {
  height: 72px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: rgba(107, 105, 107, 0.5);

  .navbar-light {
    color: $white;
    background-color: transparent;

    .navbar-nav {
      flex-direction: row;
      align-items: center;
    }

    .navbar-toggler {
      border: none;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox…");
      }
    }

    .kizuna-nav-item {
      display: flex;
      justify-items: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &::after {
        content: ""; /* Necesario para crear el pseudo-elemento */
        position: absolute;
        left: 0;
        bottom: 8%;
        width: 100%;
        height: 1px; /* Ajusta el grosor de la barra aquí */
        background-color: white; /* Color de la barra */
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.2s ease; /* Transición suave */
      }

      &:hover::after,
      &:focus::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    .nav-link {
      color: white;
      font-family: $font-family-title;
      font-size: 1.2rem;
      &:hover,
      &:focus {
        color: white;
        text-decoration: none;
      }
    }

    .cart-icon {
      position: relative;
      font-size: 1.25rem;
      color: white;

      .cart-img--bag {
        height: 20px;
        color: $white;
      }

      .cart-count {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: 28%;
        right: 20%;
        background-color: white;
        color: $dim-gray;
        border-radius: 50%;
        font-size: 8px;
      }
    }

    .dropdown-menu {
      width: 90%;
      margin-top: 5.5px;
      left: auto;
      border-radius: 0px 0px 8px 8px;
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
      background: rgba(107, 105, 107, 0.5);
      border: none;
      .dropdown-item {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        color: white;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.Toolbar {
  // background-color: $white;
  z-index: 5;
  width: 100%;
  position: fixed;
  padding: 0;
  transition: background-color 100ms linear 120ms;
}

.logo {
  width: 160px;
}

li {
  text-align: center;
  color: #a8a8a8;
  list-style: none;
  position: relative;
  line-height: 2;
  font-size: 32px;
  padding: 0.5rem 0;
}

.kizuna-dropdown-item--container {
  position: relative;

  &:not(:last-child)::after {
    content: ""; /* Necesario para crear el pseudo-elemento */
    position: absolute;
    left: 10%; /* Ajusta el espacio desde la izquierda */
    bottom: 8%;
    width: 80%; /* Ajusta el ancho para crear espacio a los lados */
    height: 1px; /* Ajusta el grosor de la barra aquí */
    background-color: white; /* Color de la barra */
    text-align: center;
  }
}
