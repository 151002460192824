.main-container-banner-home-quickship-page {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
}

.quickship--banner-container {
  box-sizing: border-box;
  background-color: #f7f6fb76;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100vh;

  .responsive-banner-quickship {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}

.container-slider-quickshippage {
  position: relative;
  height: 100%;
  // min-height: 100vh;

  .flicking-viewport {
    // height: auto !important;
    min-height: 100vh;

    .flicking-camera {
      border: 1px solid transparent;
    }

    .flicking-panel {
      min-height: 100vh !important;
    }
  }

  .flicking-dots-quickshippage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    z-index: 10;

    margin: 0 auto;
    width: 100%;
    padding: 1rem 0;

    .flicking-dot {
      width: 35px;
      height: 5px;
      border: none;
      background-color: #d9d9d9;

      margin: 0 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      outline: none !important;

      &.active {
        background-color: #999999;
      }
    }
  }
}

.quickship--banner-container .eg-flick-container {
  display: flex;
}

.quickship--banner-container .eg-flick-panel {
  width: 100%;
  flex-shrink: 0;
}

.conatiner--img--home-baner {
  max-width: 100%;
  height: 50%;
  display: flex;

  img {
    max-width: 100%;

    @media (min-width: 1700px) {
      min-width: 100%;
    }
  }
}

.quickship--banner-left-container {
  position: relative;

  .conatiner--img--home-baner img {
    mask-image: linear-gradient(#000 85%, #eb0d0d00 100%);
    object-fit: cover;
  }
}

.conatiner--content--home-baner {
  display: flex;
  flex-direction: column;
}

.quickship--banner-right-container {
  padding: 70px 0 0 0;

  .quickship--content-banner-right-container {
    padding: 0 5rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: 1280px) {
      padding: 0 2rem;
    }

    li {
      color: #000;
    }
  }

  .conatiner--img--home-baner img {
    mask-image: linear-gradient(360deg, #000 85%, #eb0d0d00 100%);
    object-fit: cover;
  }
}

.container--andtitle-descriptcion {
  padding: 0 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 1280px) {
    padding: 0 2rem;
  }
}

.quickship--banner-about-title {
  color: #000;
  font-family: "Gill Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 0px;
}

.quickship--banner-how-works-title {
  color: #000;
  font-family: "Gill Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 30px;
}

.quickship--banner-description {
  color: #000;
  font-family: "Gill Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 20px;
  margin-bottom: 0;

  span {
    font-weight: 600;
  }

  .quickship--banner-description--list {
    font-family: "Gill Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

.btn-Mobile-home-quickship-page {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  min-width: 147px;
  text-align: center;
  min-height: 47px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 10px #0000007d;
  }
}

.quickship--banner-btn-arrow-down,
.quickship--banner-btn-arrow-down a,
.quickship--banner-btn-arrow-down a:hover {
  gap: 1rem;
  color: #000;
  font-family: "Gill Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding-top: 0.5rem;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.animationImgArrowDown--mobile {
  animation: floatArrowMobile 1s ease-in-out infinite;
}

.animationImgArrowDown--desktop {
  animation: floatArrowDesktop 1s ease-in-out infinite;
}

@keyframes floatArrowDesktop {
  50% {
    transform: translateX(-50%) translateY(5px); // Combina ambas transformaciones
  }
}

@keyframes floatArrowMobile {
  50% {
    transform: translateY(5px);
  }
}

@media (max-width: 990px) {
  .quickship--banner-container {
    height: 100vh;
    flex-direction: row; // Cambiar a 'row' para mantener lado a lado
  }

  .quickship--banner-how-works-title {
    padding-left: 1rem;
  }

  .responsive-banner-quickship {
    width: 100% !important;
  }

  .conatiner--img--home-baner {
    height: 20%;
    img {
      min-width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .quickship--banner-container {
    // min-height: auto;
    flex-direction: row; // Cambiar a 'row' para mantener lado a lado
  }

  .quickship--banner-description {
    font-size: 16px;
    padding-top: 0;
  }

  .container--andtitle-descriptcion {
    padding: 0 1rem;

    .quickship--banner-about-title {
      padding: 1rem 0 0 0;
    }

    img {
      margin: 1rem 0;
    }
  }

  .quickship--banner-btn-arrow-down {
    margin-top: 0rem;
  }

  .quickship--banner-left-container {
    padding: 0 0 2rem 0;
    height: 100%;
  }

  .quickship--banner-right-container {
    padding: 50px 0 0 0;
    height: 100%;

    .quickship--content-banner-right-container {
      padding: 1rem 0;

      li {
        color: #000;
      }
    }
  }
}

@media (max-width: 360px) {
  .flicking-viewport {
    // height: auto !important;
    // min-height: 100vh !important;
  }
}
