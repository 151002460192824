@include media-breakpoint-up(md) {
  .product--information-detail--main-container {
    padding-block: 20px;
    padding-inline: 40px;
    margin-bottom: 8px;
  }

  .product--information-detail-value {
    line-height: normal;
  }
}
