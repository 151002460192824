@include media-breakpoint-down(lg) {
  .footer--masiala {
    justify-content: center;
    height: 72px;
  }

  .footer-logo--masiala {
    height: 40px;
  }
}
