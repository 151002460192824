@include media-breakpoint-down(lg) {
  .wrapper--seg-hotelery {
    height: 360px;
  }
  .container--seg-hotelery {
    height: 360px;
  }

  .title--seg-hotelery {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .description--seg-hotelery {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1rem;
  }

  .btn--seg-hotelery {
    margin-top: 2rem;
    font-size: 0.6rem;
  }
}
