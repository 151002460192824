.main-menu-sections-masiala {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.main-menu-sections__section-masiala > div:after {
  background: $white;
  content: "";
  display: block;
  height: $menu-underline-height;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.main-menu-sections__section-masiala:hover > div:after {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  transform: scaleX(1);
}

.header-main-menu-masiala {
  z-index: 1;
}

.header-menu-button-masiala {
  display: block;
  color: $white;
  background-color: transparent;
  border: none;
  text-align: left;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
    box-shadow: none;
    color: $white;
  }
}

.header-menu-button-masiala--active:after {
  background: $white;
  content: "";
  display: block;
  height: $menu-underline-height;
  transform: scaleX(1);
}
