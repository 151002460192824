@include media-breakpoint-down(sm) {
  .main-img {
    height: 240px;
  }

  .secondary-img {
    width: 240px;
  }

  .info--segm-hotelery--technical-upholstery {
    margin-top: 5rem;
  }
}
