@include media-breakpoint-down(lg) {
  .img-container {
    width: 152px;
    height: 88px;
  }

  .hover-icon {
    opacity: 1;
    bottom: -24%;
    right: 4%;
  }
}
