.desc--vid-rv {
  width: 70%;
  color: $gray;
  font-size: $h1-font-size;
  font-family: $font-family-base;
  font-weight: 300;
}

.video-saxony > div {
  width: 100% !important;
  height: 100%;
}