@include media-breakpoint-up(lg) {
  .box-hw {
    position: absolute;
    width: 40%;
    padding: 3rem;
    top: 24%;
    right: 0%;
    z-index: 1;
  }

  .paragraph {
    font-size: 1.063rem;
    font-weight: 550;
  }

  .title-approve-cleaners {
    font-size: 1.063rem;
  }

  .do-not-use-alcohol-description, .approve-cleaners-description, .keep-in-mind-description-list {
    font-size: 1.063rem;
    line-height: normal;
  }

}

// WARNING: use this for large screens
@media (min-width: 1441px) {
  .paragraph {
    width: 60%;
  }
}
