@include media-breakpoint-only(md) {
  .saxony--section-container {
    height: 400px !important;
  }

  .saxony--container-img {
    position: absolute;
    right: 5%;
  }

  .saxony--characteristic-description,
  .saxony--environment,
  .saxony--phatalateFree-descr,
  .saxony--biocideFree-descr {
    font-size: 1.125rem;
  }

  .saxony--phatalateFree-img {
    width: 95px;
    height: 23px;
    flex-shrink: 1;
  }

  .saxony--biocideFree-img {
    height: 23px;
    width: 75px;
  }
}
