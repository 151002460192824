@include media-breakpoint-down(md) {
  .feature-img--kizuna {
    width: 32%;
  }

  .application-container {
    margin: 0 0.5rem;
  }

  .application-img {
    width: 32px;
    height: 32px;
  }

  .description-feature {
    font-size: 0.875rem;
  }
}
