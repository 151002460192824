@include media-breakpoint-down(md) {
  .custom-tag {
    height: 360px;
  }

  .slider-info--container {
    width: 100%;
    padding: 2rem 1rem;
    margin: 1.5rem 0;

    h1 {
      font-size: $font-size-lg;
    }
    p {
      font-size: $font-size-sm;
    }
  }

  .section-design--container {
    height: 464px;

    .section-design--img {
      background-size: auto;
      background-position: center -8rem;
    }
  }
}
