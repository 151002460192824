@include media-breakpoint-up(lg) {
  .sustainable-modal--produced--with-container,
  .sustainable-modal--recicled-bottles {
    padding-inline: 150px;
  }

  .container-sustainable-modal-imgs {
    margin-top: 40px;
  }

  .bg-reseda-green {
    padding-inline: 150px;
  }

  .bg-reseda-green {
    background-color: $reseda-green;
    padding-block: 70px !important;
  }

  .img-section-3 {
    max-width: 900px;
    position: relative;
    right: 70px;
    width: 130%;
  }
}
