.design-navigator--wrapper {
  position: relative;
}

.design-navigator--container {
  position: relative;

  .basic-title {
    display: block;
    padding: 1rem 0;
    font-size: 30px;
    text-align: center;
    color: $gray-light;
  }

  .filtered-references--container {
    display: flex;
  }

  .card-pattern {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 376px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-size: cover;

    .card-title {
      z-index: 1;
      font-size: 35px;
      color: $white;
      transition: all 0.4s ease-out;
    }

    .card-label {
      display: none;
      z-index: 1;
      color: $white;
      text-decoration: underline;
      transition: all 0.4s ease-out;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black;
      opacity: 0;
      transition: opacity 0.4s ease;
    }
  }

  .card-pattern:hover {
    .overlay {
      opacity: 0.5;
    }

    .card-title,
    .card-label {
      display: block;
      transform: translate(0, -8px);
    }
  }
}
