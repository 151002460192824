.kizuna-detail-sample-title {
  text-align: left;
  text-transform: capitalize;
  color: #6c757d;
}

.btn-free-catalogue--kizuna {
  background-color: #969494;
}

.limit-samples-label {
  margin: 0;
  color: $dim-gray;
  font-weight: 300;
  text-align: center;

  strong {
    font-weight: 700;
  }
}
