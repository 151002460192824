@include media-breakpoint-up(xxl) {
  .wrapper-samples-details--masiala {
    padding-left: 130px;
    padding-right: 130px;
  }
  
  .title-samples-details--masiala {
    margin: 2rem 0rem;
  }
}
