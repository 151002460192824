.color-detail {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 1rem 0;
  padding: 8rem 0;
  position: relative;
  overflow-y: hidden;
}

.color-detail--rectangle {
}

.color-detail--square {
  height: 28rem;
}

.color-detail-text-container {
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  background-color: $gray-lighter;
}

.color-detail--label {
  font-family: $font-family-title;
  font-size: $h4-font-size;
  text-transform: capitalize;
}

.color-detail--stock {
  font-family: $font-family-base;
  font-size: 0.8rem;
}
