.info {
  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: $font-weight-light;
  width: 80%;
  vertical-align: inherit;
}

.info > td::after {
  content: "";
  display: block;
  margin-bottom: 4px;
}

.disclaimer {
  font-family: $font-family-base;
  font-size: 0.75rem;
  font-weight: $font-weight-light;
  font-style: italic;
}