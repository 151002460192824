@include media-breakpoint-down(sm) {
  .header--kizuna {
    .navbar-light {
      .navbar-collapse {
        flex-basis: min-content;
      }

      .nav-link {
        font-size: 0.875rem;
      }
    }
  }
}
