%flex-element {
  display: flex;
  align-items: center;
}

.progress-container {
  bottom: 0;
  right: 2rem;
}


.progress-bar-container {
  @extend %flex-element;
  justify-content: flex-end;
  position: relative;
  padding: 1rem;
}

.progress-bar-text {
  position: absolute;
  top: 32%;
  color: $black;
}

.progress-bar {
  background-color: $gray-400;
  height: 0.5rem;
  width: 20rem;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-right: 1rem;
  align-self: flex-end;
}

.progress-bar__inner {
  position: relative;
  display: block;
  background-color: $gray-700;
  animation: run-progress 1s;
}

.progress-bar__inner-text {
  color: $gray-700;
  font-size: x-small;
}

@keyframes run-progress {
  0% {
    width: 0;
  }
}

.progress-btn-up {
  @extend %flex-element;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0.1rem solid $gray-700;

  &:hover {
    border-right: 0.1rem solid $gray-700;
  }
}

.progress-btn-down {
  @extend %flex-element;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0.1rem solid $gray-700;

  &:hover {
    border-left: 0.1rem solid $gray-700;
  }
}
