.wrapper--seg-hotelery {
  position: relative;
  background-position: center center;
  background-size: cover;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.container--seg-hotelery {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $white;
}

.title--seg-hotelery {
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 4rem;
  color: $white;
  font-weight: bold;
  line-height: 4rem;
}

.description--seg-hotelery {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: $white;
  line-height: 2rem;
}

.btn--seg-hotelery {
  margin-top: 3rem;
  background-color: $white;
  color: #6c757d;

  &:hover {
    color: #6c757d;
  }
}
