.custom-tag {
  background-size: cover;
  background-position: center;
  height: 416px;
}

.slider-info--container {
  width: 60%;
  padding: 2rem 2rem 2rem 8rem;
  border-radius: 8px;
  background: rgba(110, 110, 110, 0.57);
  backdrop-filter: blur(5.5px);
  color: $white;

  h1 {
    font-size: 1.75rem;
  }
}

.section-design--container {
  height: 710px;

  .section-design--img {
    display: flex;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .section-design--desc {
    white-space: pre-line;
  }
}
